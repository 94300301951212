<template>
  <v-container
    fluid
    fill-height
    align-center
    justify-center
    class="pa-0 map-container"
  >
    <v-card elevation="12">
      <v-card-title primary-title outlined class="font-weight-bold">{{
        $t("discovery.pick_type_title")
      }}</v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pa-0 center-block-content">
        <v-container d-flex wrap class="pa-0" fluid>
          <v-row no-gutters class="pa-4">
            <v-col cols="12" sm="6" class="pa-4">
              <card-detect />
            </v-col>

            <v-col cols="12" sm="6" class="pa-4">
              <card-instant-search />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CardDetect from "./CardDetect";
import CardInstantSearch from "./CardInstantSearch";

export default {
  components: {
    CardDetect,
    CardInstantSearch
  }
};
</script>
